import { useState, useContext, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import Styles from '../../../../styles/pages/home.module.scss';
import { GeneralConfigContext } from '../../../general-config-provider';
import { useTranslations } from 'next-intl';

import {
  formatterMoney,
  splitField,
  getMunicipalityDepartmentID,
  getMunicipalityName,
  getDepartmentName,
  getTouristServiceTypeMuncipalityID,
} from '../../../../shared/utils';
import { Button, Carousel, Col, Row, Tag } from 'antd';
import Link from 'next/link';
import { ButtonType } from 'antd/es/button';

type RecommendedOffersSectionType = {
  title?: string;
  offers?: any[];
  buttonType?: ButtonType;
  arrows?: boolean;
  blackarrows?: boolean;
};

export default function RecommendedOffersSection({
  title,
  offers,
  buttonType = 'primary',
  arrows = true,
  blackarrows = true,
}: RecommendedOffersSectionType) {
  const router = useRouter();
  const [locale, setLocale] = useState(router?.locale);
  const { generalConfig } = useContext(GeneralConfigContext);
  const [numberState, setNumberState] = useState(1);
  const inputElement = useRef<HTMLDivElement>(null);
  const t = useTranslations('townships.Turistic');
  const r = useTranslations('viewOffer');
  const [batchSize, setBatchSize] = useState(10); // Tamaño por defecto
  const [xl, setXl] = useState(4); // Tamaño por defecto
  const [lg, setLg] = useState(5); // Tamaño por defecto

  let lenghtList = offers.length;

  const municipalityID = (offer) => {
    return getTouristServiceTypeMuncipalityID(offer);
  };

  const scrollLeft = (number: number) => {
    if (inputElement !== null) {
      // window.scroll(0,inputElement.current.offsetLeft)
      if (number === 1 && numberState < lenghtList) {
        inputElement.current.scrollLeft += 310;
        setNumberState(numberState + 1);
      } else if (number === 2 && numberState > 1) {
        inputElement.current.scrollLeft -= 310;
        setNumberState(numberState - 1);
      }
    }
  };

  useEffect(() => {
    if (offers.length === 0) setNumberState(0);
    return () => {
      setNumberState(1);
    };
  }, [offers]);

  const redirection = (id: number) => {
    router.replace('/servicio/' + id).then();
  };

  // Función para ajustar el batchSize según el tamaño de la ventana
  const updateBatchSize = () => {
    const width = window.innerWidth;
    if (width < 576) {
      setBatchSize(1); // 1 tarjeta en pantallas pequeñas
      setXl(4);
    } else if (width >= 576 && width < 1300) {
      setBatchSize(2); // 2 tarjetas en pantallas medianas
      setXl(6);
    } else if (width >= 1300 && width < 1900) {
      setBatchSize(3); // 2 tarjetas en pantallas medianas
      setXl(5);
    } else {
      setBatchSize(4); // 4 tarjetas en pantallas grandes
      setXl(3);
      setLg(8);
    }
  };

  // useEffect para actualizar el batchSize cuando cambie el tamaño de la ventana
  useEffect(() => {
    updateBatchSize(); // Ajustar el batchSize al cargar la página
    window.addEventListener('resize', updateBatchSize); // Escuchar cambios de tamaño

    return () => {
      window.removeEventListener('resize', updateBatchSize); // Limpiar el listener al desmontar el componente
    };
  }, []);

  // Agrupar las ofertas en lotes de tamaño batchSize
  const groupedOffers = [];
  for (let i = 0; i < offers.length; i += batchSize) {
    groupedOffers.push(offers.slice(i, i + batchSize));
  }

  return (
    <div>
      {title && <p>{title}</p>}

      <div>
        <Carousel
          autoplaySpeed={10000}
          arrows={arrows}
          autoplay
          className={blackarrows ? `custom-slick-black-arrows` : 'custom-slick'}
        >
          {groupedOffers.map((group, index) => (
            <div key={index}>
              <Row
                key={index}
                gutter={[16, 16]}
                className={Styles.content_offers_tourist}
              >
                {group.map((item) => (
                  <Col
                    xs={24} // Una tarjeta por fila en pantallas pequeñas
                    sm={12} // Dos tarjetas en pantallas medianas
                    lg={lg} // Cuatro tarjetas en pantallas grandes
                    xl={xl} // Cuatro tarjetas en pantallas grandes
                    key={item.id_oferta}
                    className={Styles.content_cards_offers}
                    style={{ padding: '8px' }}
                  >
                    <div
                      className={Styles.content_cards_offers_item}
                      style={{ overflow: 'hidden' }}
                      onClick={() => redirection(item.id_oferta)}
                    >
                      <img
                        alt="image"
                        src={
                          process.env.NEXT_PUBLIC_STORAGE_URL +
                          item?.imagenes[0]?.ImagenURL
                        }
                      />
                      <p className={Styles.department_text}>
                        {getDepartmentName(
                          getMunicipalityDepartmentID(
                            municipalityID(item),
                            generalConfig?.ciudad
                          ),
                          generalConfig?.departamento
                        )}{' '}
                        {getMunicipalityName(
                          municipalityID(item),
                          generalConfig?.ciudad
                        )}
                      </p>
                      <p className={Styles.title_text}>
                        {splitField(item?.nombre, locale)}
                      </p>
                      <p className={Styles.subtitle_text}>
                        {t('By')} <b>{item?.PST?.razon_social}</b>
                      </p>
                      <div className={Styles.group_star}>
                        <img alt="image" src="/images/municipios/star.svg" />
                        <p>{item.calificacion}</p>
                      </div>
                      <p className={Styles.card_paragraph}>
                        {splitField(item.descripcion, locale)}
                      </p>
                      <div className={Styles.group_price}>
                        <p className={Styles.desde}>{t('From')}</p>
                        <p
                          style={{ display: 'flex', alignItems: 'center' }}
                          className={Styles.price_green}
                        >
                          {router.locale === 'es' ? (
                            <>
                              <Tag bordered={false} color="processing">
                                COP
                              </Tag>
                              {formatterMoney.format(item.valor_calculado)}
                            </>
                          ) : (
                            <>
                              <Tag
                                bordered={false}
                                color={
                                  item.valor_calculado_usd > 0
                                    ? 'success'
                                    : 'processing'
                                }
                              >
                                {item.valor_calculado_usd > 0 ? 'USD' : 'COP'}
                              </Tag>
                              {formatterMoney.format(
                                item.valor_calculado_usd > 0
                                  ? item.valor_calculado_usd
                                  : item.valor_calculado
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </Carousel>
      </div>

      {/* <div className={Styles.content_cards_offers} ref={inputElement}></div> */}
      {/*  <div className={Styles.control_buttons_slider}>
        <div
          className={classNames(Styles.button_slider, Styles.rotate)}
          onClick={() => scrollLeft(2)}
        >
          <img src="/icons/chevronright.svg" alt="arrow" id="btnScrollLeft" />
        </div>
        <p>{numberState + ' / ' + lenghtList}</p>
        <div className={Styles.button_slider} onClick={() => scrollLeft(1)}>
          <img src="/icons/chevronright.svg" alt="arrow" id="btnScrollLeft" />
        </div>
      </div> */}
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button
          type={buttonType}
          onClick={() => {
            router.push('/busqueda');
          }}
        >
          {r('todosLosServicios')}
        </Button>
      </div>
    </div>
  );
}
